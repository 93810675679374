import Lottie from "lottie-react";
import React from "react";
import { Card } from "react-bootstrap";
import Animation404 from "../../../utils/lottieFiles/404-animation.json";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";

function Page404() {
  return (
    <div>
      <div className="vh-100 d-flex justify-content-center align-items-center overflow-x-hidden">
        <Card className="container d-flex flex-column justify-content-center align-items-center pb-5">
      <div className="not-found-one"></div>
      <div className="not-found-two"></div>
        <Lottie animationData={Animation404} loop={true} />
          <h1>
          <span className="gradient-heading">Sorry!</span> this page is not available
          </h1>
          <p>The page you were looking for, couldn't be found.</p>
          <Link className="caseworking-card-btn text-decoration-none d-flex justify-content-center align-items-center px-3 rounded-3 mt-3" to={'/'}>
              <IoMdHome className="me-2"/>
              Back to Home
          </Link>
        </Card>
      </div>
    </div>
  );
}

export default Page404;
