import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import MoonLoader from "react-spinners/MoonLoader";
import { requestData } from "../../../utils/ApiRequests";
import { AllContext } from "../../../context/AllContext";
import Heading from "../../../components/Heading";
import ClassCard from "../../../components/ClassCard";

function Classes() {
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(false);
  const {isMobile,isTablet,isBigScreen}= useContext(AllContext);

  const getClassList = async () => {
    setLoading(true);
    const res = await requestData("/latestClassList", "POST", {
      start_index: "0",
      no_of_records: "100",
    });
    // console.log(res,'classes')
    if (res.error === false) {
      setClassList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getClassList();
  }, []);

  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    slidesToShow: isMobile? 1: isTablet?2 : 3,
    speed: 500,
    rows:(isMobile || isTablet)? 1: 2,
    // autoplay:true,
    // dots:true,
    // slidesPerRow: 3,
  };

  return (
    <div className="liveclasses pt-2 position-relative container">
      {/* <div>
        <h3 className="text-center lh-1 m-0">Silva Method's</h3>
        <CustomHeader title={"Upcomming"} colored={"Classes"} />
      </div> */}
      {isBigScreen && <div style={{position:'absolute', top:0,right:0}}>
            <img src="/assets/img/bird2.webp" alt="" style={{width:'300px',height:'200px'}}/>
        </div>}
      <div>
      <Heading title={"Upcomming"} colored={"Classes"} subtitle={"Silva Method's"}/>
      </div>
      <div>
      </div>
    <div className="container">
       {
        loading?
        <div className="d-flex flex-column w-100 align-items-center justify-content-center p-5">
          <MoonLoader
          loading={loading}
          size={100}
          color="#250f74"
          />
        </div>:
      <Slider {...settings}>
        {classList &&
          classList.map((classItem, i) => (
            <ClassCard key={i} data={classItem} index={i}/>
          ))}
      </Slider>
       }
    </div>
    </div>
  );
}

export default Classes;