import { useContext, useEffect } from 'react';
import './App.css';
import AllRoutes from './routes/AllRoutes';
import { AllContext } from './context/AllContext';

function App() {

  const{updateUser}= useContext(AllContext);

  const token = JSON.parse(localStorage.getItem("silvacasetoken"));

  useEffect(()=>{
    if(token){
      updateUser(token);
    }
  },[token]);


  return (
    <div>
      <AllRoutes/>
    </div>
  );
}

export default App;
