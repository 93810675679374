import React, { useContext, useEffect, useState } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from "react-slick";
import MoonLoader from "react-spinners/MoonLoader";
import { requestData } from "../../../utils/ApiRequests";
import { AllContext } from "../../../context/AllContext";
import Heading from "../../../components/Heading";
import SliderCard from "../../../components/SliderCard";


function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isMobile,isTablet,isBigScreen } = useContext(AllContext);

  const settings = {
    className: "center",
    infinite: true,
    cssEase: "linear",
    centerMode:  true,
    slidesToShow: 1,
    speed: 500,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    dots: true,
    centerPadding: (isMobile || isTablet) ? "10px" :isBigScreen?"400px":"100px" ,
    // autoplay: true
  };

  const getReviews = async () => {
    setLoading(true);
    const res = await requestData("/testimonialList", "POST", {
      start_index: 0,
      no_of_records: 100,
    });

    // console.log(res,'response...')
    if (res.error === false) {
      setReviews(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <div className="pt-5 padding-bottom-80" style={{backgroundColor:'#f5f6fa',overflowX:'hidden'}}>
      <Heading
        title={"Say"}
        colored={"About Us"}
        subtitle={"See what others have to"}
      />
      {loading ? (
        <div className="d-flex flex-column w-100 align-items-center justify-content-center p-5">
          <MoonLoader loading={loading} size={100} color="#250f74" />
        </div>
      ) : (
        <div className="reviews-carousel my-5">
          <Slider {...settings}>
            {reviews.map((review, i) => (
              <div key={i}>
                <SliderCard data={review} />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default Reviews;
