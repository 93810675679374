import React, { useContext } from 'react'
import Heading from '../../../components/Heading'
import ParaComp from '../../../components/ParaComp'
import { AllContext } from '../../../context/AllContext'
import Strip from './Strip';

function BriefSection() {

    const {isMobile,isTablet}= useContext(AllContext);

  return (
    <div className='brief-container pb-5 position-relative'>
        <div className="container d-flex flex-column justify-content-center align-items-center px-3">
            <Heading title={"How The Silva Case Working Operates"} white centered={isMobile?false:true}/>
            <ParaComp
                data={[
                    "The heart and soul of the Silva Method is the Case Work. It is a psychometry exercise, where one person act as a guide, also known as orientologist and another as a psychic. The guide initiates the process by presenting a Case, which includes essential details such as name, age, location, and gender, as well as pertinent information about the subject's health or situation. Following the orientologist's guidance, the psychic enters a state of heightened consciousness, reaching alpha or theta levels of mind. Within this state, they visualize the subject on their mental canvas, discerning potential issues they may be facing. Then with the guide's concern he send healing energy to the subject.",
                    "However, the essence of Silva Case Work extends beyond these fundamental steps. Silva cases merges it's principle with community healing. Regardless of location, individuals can submit cases world wide and receive remote healing from the Silva graduates."
                ]}
                white
                fw={"400"}
            />
            <img src="/assets/img/transparent_hero_image.webp" alt="" style={{width:(isMobile || isTablet)?"100%":"50%"}}/>
        </div>
        <div className='position-absolute bottom-0 left-0 w-100'>
            <Strip/>
        </div>
    </div>
  )
}

export default BriefSection