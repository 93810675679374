import React from 'react'
import ResetHero from './ResetHero'

function ResetPassword() {
  return (
    <div>
        <div style={{backgroundImage:`url('/assets/img/user-home-bg.jpg')`,backgroundPosition:'center',backgroundSize:'cover'}}>
            <ResetHero/>
        </div>
    </div>
  )
}

export default ResetPassword