import React, { useContext } from "react";
import { Button} from "react-bootstrap";
import { AllContext } from "../../../context/AllContext";
import { Link } from "react-router-dom";

function Strip() {


  const {isMobile,isTablet}= useContext(AllContext)
  return (
    <div
      style={{background: "#ffffff33", overflowX: "hidden" }}
      className="py-3"
    >
      <div className="container">
        <div className={`d-flex flex-wrap gap-${isMobile || isTablet ?'2':'5'} justify-content-center align-items-center text-center`}>
        <p className="text-white m-0">
        Improve your ESP to heal others
        </p>
        <Link to={'/authenticate'}><Button variant="outline-light">Register/Login</Button></Link>
        </div>
      </div>
    </div>
  );
}

export default Strip;
