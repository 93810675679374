import Lottie from "lottie-react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import TermsAnimation from "../../utils/lottieFiles/terms-animation.json";

function TermHero() {
  return (
    <div className="container">
          <div className="my-3">
            <h3>Terms and Conditions</h3>
          </div>
          <div className="mt-3">
            <p>
              Thank you for visiting the Silva Method website. By accessing and
              using this website, you agree to be bound by the following terms
              and conditions:
            </p>
          </div>
      <Row>
        <Col sm={12} md={12} lg={6} xl={6}>
          <Lottie animationData={TermsAnimation} loop={true} />
        </Col>
        <Col sm={12} md={12} lg={6} xl={6} className="d-flex justify-content-center align-items-center">
          <div>
          <p className="mb-3">
            <span style={{ fontWeight: "bolder" }}>Content Ownership</span>: All
            content on this website, including but not limited to text, images,
            audio, video, and logos, is the property of the Silva International
            Inc. or its affiliates and is protected by copyright laws. You may
            not use any of the content without our prior written consent.
          </p>
          <p className="mb-3">
            <span style={{ fontWeight: "bolder" }}>Use of Website</span>: You
            agree to use our website only for lawful purposes and in a manner
            that does not infringe the rights of, restrict, or inhibit any other
            user from using or enjoying our website. You may not use this
            website to engage in any illegal or unauthorized activities,
            including but not limited to hacking, spreading viruses, or
            disrupting the website's functionality. You shall not use our
            website to transmit or upload any material that is unlawful,
            threatening, defamatory, obscene, or otherwise objectionable.
          </p>
          <p className="mb-3">
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              User Generated Content:
            </span>{" "}
            Any content that you post on the website, including but not limited
            to comments, reviews, and feedback, becomes the property of the
            Silva International Inc. You grant us a non-exclusive, worldwide,
            royalty-free license to use, reproduce, modify, and distribute your
            content.
          </p>
          </div>
        </Col>
      </Row>
          <p>
            <span>
              By using our website, you acknowledge that you have read and
              understood these terms and conditions and agree to be bound by
              them. If you have any questions or concerns about these terms and
              conditions, please contact us.
            </span>
          </p>
    </div>
  );
}

export default TermHero;
