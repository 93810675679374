import React, { useContext } from "react";
import { AllContext } from "../context/AllContext";
import CustomHeader from "./CustomHeader";

function Heading({title,colored,subtitle,white,centered}) {

  const {isDesktop,isTablet} = useContext(AllContext);

  return (
    <div className="pt-4">
      <h3 className="text-center lh-1 my-3" style={{fontSize: isDesktop?'25px':isTablet?'20px':'15px'}}>{subtitle}</h3>
      <CustomHeader title={title} colored={colored} white={white} centered={centered}/>
    </div>
  );
}

export default Heading;
