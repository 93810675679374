import React from 'react'

function Footer() {
  return (
    <div style={{backgroundColor: '#121110de',width:'100%'}}>
        <div className='d-sm-flex py-3 justify-content-center align-items-center text-center text-white'>
            <p>&copy; 2024 Silva Cases Online,</p>
            <p>Crafted By <span style={{color:'#be01dd'}}>Semicolon Ites</span></p>
        </div>
    </div>
  )
}

export default Footer