import React, { useState } from "react";
import LeftContainer from "./LeftContainer";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { Toaster } from "react-hot-toast";

function Login() {
  const [defaultActive, setDefaultActive] = useState(0);

  return (
    // <!-- Section: Design Block -->
    <section className="background-radial-gradient vh-100 d-flex justify-content-center overflow-x-hidden">
      <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActive}>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Nav
              variant="pills"
              className="flex-row justify-content-center py-3 gap-3"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey={0}
                  className="text-white px-5 logintabs"
                  onClick={() => setDefaultActive(0)}
                >
                  Login
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={1}
                  className="text-white px-5 logintabs"
                  onClick={() => setDefaultActive(1)}
                >
                  Register
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey={0}>
                <div className="container px-4 px-md-5 text-center text-lg-start">
                  {/* <TabsSection /> */}
                  <div className="row gx-lg-5 align-items-center mb-5 align-items-center">
                    <LeftContainer
                      title="Login"
                      colored="Account"
                      text="Login to the Silva Cases, a unique platform that brings together the principles of the renowned Silva Method and the power of community healing. Submit your case and get free healing assistance from our growing international network of healers. Or pick up cases, improve your ESP, and transmit healing energy to those in need."
                    />
                    {/* <RightContainer/> */}
                    <LoginForm />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={1}>
                <div className="container px-4 px-md-5 text-center text-lg-start">
                  {/* <TabsSection /> */}
                  <div className="row gx-lg-5 align-items-center mb-5">
                    <LeftContainer
                      title="Register"
                      colored="Account"
                      text="Register to the Silva Cases, a unique platform that brings together the principles of the renowned Silva Method and the power of community healing. Submit your case and get free healing assistance from our growing international network of healers. Or pick up cases, improve your ESP, and transmit healing energy to those in need."
                    />
                    <RegisterForm />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <Toaster />
    </section>
    // <!-- Section: Design Block -->
  );
}

export default Login;
