import React from 'react'
import TermHero from './TermHero'
import PublicNavbar from '../../layouts/Navbar'
import Footer from '../../layouts/Footer'

function Terms() {
  return (
    <div className='benifits-card w-100 vh-100 position-relative overflow-hidden'>
        <PublicNavbar/>
        <div className='mt-5 overflow-y-auto termssec' style={{height:'75vh'}}>
          <TermHero/>
        </div>
        <div className='position-absolute bottom-0 w-100'>
        <Footer/>
        </div>
    </div>
  )
}

export default Terms