// import { ZoomMtg } from '@zoom/meetingsdk';

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

// function ZoomMeet() {
//   var authEndpoint = 'https://silvacasesonline.com:5000/'
//   var sdkKey = 'mfAXqUOkQUiUM5PGSnIzCg'
//   var meetingNumber = 83980710216
//   var password = "XExb79YKJUKaZf5zFd3TAObV0d0B9U.1"
//   var role = 0
//   var userName = 'React'
//   var userEmail = 'sukanta@semicolonites.com'
//   var leaveUrl = 'http://localhost:3000'

//   function getSignature(e) {
//     e.preventDefault();

//     fetch(authEndpoint, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         meetingNumber: meetingNumber,
//         role: role
//       })
//     }).then(res => res.json())
//       .then(response => {
//         startMeeting(response.signature)
//       }).catch(error => {
//         console.error(error)
//       })
//   }

//   function startMeeting(signature) {
//     document.getElementById('zmmtg-root').style.display = 'block'

//     ZoomMtg.init({
//       leaveUrl: leaveUrl,
//       patchJsMedia: true,
//       success: (success) => {
//         console.log(success)

//         ZoomMtg.join({
//           signature: signature,
//           sdkKey: sdkKey,
//           meetingNumber: meetingNumber,
//           userName: userName,
//           userEmail: userEmail,
//           passWord: password,
//           success: (success) => {
//             console.log(success)
//           },
//           error: (error) => {
//             console.log(error)
//           }
//         })

//       },
//       error: (error) => {
//         console.log(error)
//       }
//     })
//   }

//   return (
//     <div className="App">
//       <main>
//         <h1>Zoom Meeting SDK Sample React</h1>

//         <button onClick={getSignature}>Join Meeting</button>
//       </main>
//     </div>
//   );
// }

// export default ZoomMeet;
import React from 'react'

function ZoomMeet() {
  return (
    <div>ZoomMeet</div>
  )
}

export default ZoomMeet