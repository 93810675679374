import React from "react";
import { Card } from "react-bootstrap";
import { Link} from "react-router-dom";

function CourseCard({ data }) {

  // console.log('combo_up_image',data)

  return (
    <Link to={`https://silvamethod.com/store/course/${data?.course_id}`}>
    <Card
      style={{
        backgroundImage: `url(${
          data?.course_id === 'combo-plan'? '/assets/img/combo_up_image.jpg':data?.web_vertical_image ||
          "https://silvamethod.com/manage/assets/uploads/noimg.jpg"
        })`,
      }}
      className="product-card mb-2"
    >
      <Card.Body>
        <div className="price-section">
          {data?.discount_percentage && data?.discount_percentage !== 0 && (
            <h3 style={{ textDecoration: "line-through", color: "red" }}>
              <h3 style={{ color: "white" }} className="text-center">
                ${data?.price}
              </h3>
            </h3>
          )}

          {data?.discount_percentage && data?.discount_percentage !== 0 && (
            <h4 style={{ color: "white" }} className="text-center">
              $
              {Math.floor(
                data?.price -
                  (data?.discount_percentage * parseInt(data?.price)) / 100
              )}
            </h4>
          )}
        </div>
        <div className="product-card-bottom-section">
          <h4>{data?.course_title}</h4>
          {data?.total_chapters && data?.total_lessons ? (
            <h5>
              {data?.total_chapters}{" "}
              {data?.total_chapters < 2 ? "Chapter" : "Chapters"} |{" "}
              {data?.total_lessons}{" "}
              {data?.total_lessons < 2 ? "Lesson" : "Lessons"}
            </h5>
          ) : (
            <h5>48 Chapters | 238 Lessons</h5>
          )}

          <div className="product-card-cta">
            <button className="product-card-cta-btn">
              {"Enroll Now"}
            </button>
            <span className="text-white">Life Time Access</span>
          </div>
        </div>
      </Card.Body>
    </Card>
    </Link>
  );
}

export default CourseCard;
