import React, { useContext, useState, useEffect } from 'react';
import PublicNavbar from '../../../layouts/Navbar';
import FirstHero from './FirstHero';
import Strip from './Strip';
import CaseWorking from './CaseWorking';
import Classes from './Classes';
import BenifitSection from './BenifitsSection';
import Reviews from './Reviews';
import Courses from './Courses';
import WhatsAppQR from './WhatappQR';
import SilvaMethodDesc from './SilvaMethodDesc';
import { AllContext } from '../../../context/AllContext';
import ContactSection from './ContactSection';
import BriefSection from './BriefSection';
import FreeMed from '../../../components/FreeMed';
import NewsLetterModal from './NewsLetterModal';
import Cookies from 'js-cookie';

function Home() {
  const { userData } = useContext(AllContext);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const modalCount = parseInt(Cookies.get("newslettershow") || 0);
    if (modalCount < 3) {
      const timer = setTimeout(() => {
        handleShow();
        const newCount = modalCount + 1;
        Cookies.set("newslettershow", newCount.toString(), { expires: 5 });
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <div>
      <div className="benifits-card">
        <PublicNavbar />
        <FirstHero />
      </div>
      <BriefSection />
      <CaseWorking />
      {/* <FreeMed /> */}
      <ContactSection />
      <Classes />
      <BenifitSection />
      <Reviews />
      <Courses />
      <WhatsAppQR />
      <SilvaMethodDesc />
      <NewsLetterModal show={show} onHide={handleClose} />
    </div>
  );
}

export default Home;
