import React, { useContext } from 'react'
import { AllContext } from '../../context/AllContext';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute() {

    const { userData} = useContext(AllContext);

    if(!userData){
        return <Navigate to={"/authenticate"}/>
    }

  return (
    <>
        <Outlet/>
    </>
  )
}

export default PrivateRoute