import React, { useContext, useState } from "react";
import { Modal, Container, Form, FormControl, Spinner } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import toast from "react-hot-toast";
import { requestData2 } from "../../../utils/ApiRequests";
import { AllContext } from "../../../context/AllContext";
import { useNavigate } from "react-router-dom";

function VerifyEmailModal(props) {
  const [code, setCode] = useState("");
  const token = JSON.parse(localStorage.getItem("silvacasetoken"));
  const { updateUser } = useContext(AllContext);
  const navigate = useNavigate();
  const [loading,setLoading]= useState({state:false,name:''})

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!code) {
      toast.error("please enter your code");
      return;
    }
    // console.log(code, "code");
    setLoading({state:true,name:'verify'})

    const response = await requestData2("/auth/verifyEmail", "POST", {
      code: code.trim(),
    });
    if (response.success) {
      props.setRegisterValues({
        name: "",
        email: "",
        password: "",
        confirmpass: "",
        countryCode: "",
        phone: "",
      })
      toast.success(response?.data);
      if (token) {
        updateUser();
      }
      props.onHide();
      navigate("/caseworking");
    } else {
      toast.error(response.error);
    }
    setLoading({state:false,name:''})
  };

  const resendEmail = async (e) => {
    e.preventDefault();
    if(!props?.registerValues?.email) {
      toast.error('unable to find email.');
      return;
    };
    setLoading({state:true,name:'resend'})
    const response= await requestData2("/auth/resendemail", "POST", {email: props?.registerValues?.email});
    if(response?.success){
      toast.success(response?.message);
    }
    // console.log(response,'res');
    setLoading({state:false,name:""})
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div
        className="d-flex justify-content-end p-1"
        onClick={() => props.onHide()}
      >
        <IoMdCloseCircle color="grey" size={30} />
      </div>
      <Container className="p-5 pt-2 d-flex justify-content-center justify-content-lg-start">
        <div style={{ width: "100%" }}>
          <h3
            class="my-3 display-5 fw-bold ls-tight"
            style={{ color: "black" }}
          >
            Verify
            <span style={{ color: "#d901fc" }}> {"Email"}</span>
          </h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label className="m-1 fw-bold">Enter verification code </Form.Label>
              <FormControl
                type="tel"
                placeholder="Enter verification code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                style={{ backgroundColor: "#f8f5fc", height: "50px" }}
              />
            </Form.Group>

            <button className="benifits-card px-5 py-3 border-0 rounded mt-2 me-3">
            {
                loading.state && loading.name ==='verify' ?
                <Spinner size="sm" variant="light"/>:
                "Submit"
              }
            </button>
            <button className="benifits-card px-5 py-3 border-0 rounded mt-2" onClick={resendEmail}>
              {
                loading.state && loading.name ==='resend' ?
                <Spinner size="sm" variant="light"/>:
                "Resend"
              }
            </button>
          </Form>
        </div>
      </Container>
    </Modal>
  );
}

export default VerifyEmailModal;
