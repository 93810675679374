import React from 'react'

function ButtonBasic({text,onPress,style={backgroundColor:'#6e067d',color:'#fff'},onClick}) {
  return (
    <button style={{...style,borderRadius:'5px', padding:'10px 30px',border:'1px solid transparent',fontWeight:'bold'}} onClick={onClick}>
        {text}
    </button>
  )
}

export default ButtonBasic