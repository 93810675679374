import React from "react";

function Button({ title,onClick }) {
  return (
    // <div style={{ position: "relative", display: "inline-block", color:'#ffd401',padding:'10px 26px',borderRadius:'5px' }}>
    //   <button
    //     style={{
    //       backgroundColor: "#ffd401",
    //       color: "black",
    //       border:'1px solid transparent'
    //     }}
    //   >
    //     {title}
    //   </button>
    //   <div
    //     style={{
    //       position: "absolute",
    //       border: "1px solid #ffd401",
    //       top: "7px",
    //       left: "6px",
    //       width: "100%",
    //       height: "100%",
    //       borderRadius: "5px",
    //     }}
    //   ></div>
    // </div>
    <div style={{ position: "relative", display: "inline-block",backgroundColor: "#ffd401",padding:'10px 26px', borderRadius:'5px'}}>
      <button
        style={{
          color: "black",
          border: "1px solid transparent",
          backgroundColor:'transparent',
          fontWeight:'600'
        }}
        onClick={onClick}
      >
        {title}
      </button>
      <div
        style={{
          position: "absolute",
          border: "1px solid #ffd401",
          top: "7px",
          left: "6px",
          width: "100%",
          height: "100%",
          borderRadius: "5px",
          cursor:'pointer',
          pointerEvents: 'none',
        }}
      ></div>
    </div>
  );
}

export default Button;
