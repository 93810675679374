import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMenu } from "react-icons/io5";
import { AllContext } from "../context/AllContext";
import ButtonBasic from "../components/ButtonBasic";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'

function PublicNavbar() {

  const [active, setActive] = useState('hero');
  const { isMobile, isTablet, userData, handleLogout } = useContext(AllContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const navigate = useNavigate();


  const handleClick = (name) => {
    if (userData) {
      if (name === 'courses') {
        window.open('https://silvamethod.com/silva-method-courses')
      } else if (name === 'liveclasses') {
        window.open('https://silvamethod.com/events/live')
      }
    }
    setActive(name);
    setShowOffcanvas(false);
  };

  const handleLoginLogout = async () => {
    if (userData) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to logout from your account!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#07ab33",
        cancelButtonColor: "#de3e21",
        confirmButtonText: "Yes, Logout!"
      }).then((result) => {
        if (result.isConfirmed) {
          handleLogout();
          // toast.success('Successfully logged out');
          Swal.fire("Successfully logged out!", "", "success");
          navigate("/authenticate")
        }
      });
    } else {
      navigate("/authenticate")
    }
  }

  const handleOffcanvasToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  useEffect(() => {
    if (active) {
      const element = document.querySelector(`.${active}`);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [active]);



  return (
    <>
      <Navbar key={"lg"} expand={'lg'} className="px-0 container">
        <Container fluid>
          <Navbar.Brand className="hero">
            <Link to={"/"}>
              <img src="/assets/img/silvamethod-logo-white.png" alt="" style={{ width: isMobile ? '170px' : '300px' }} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} onClick={handleOffcanvasToggle}>
            <IoMenu color="white" />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <img src="/assets/img/silvamethod-logo.png" alt="" style={{ width: '200px' }} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={isMobile || isTablet ? 'benifits-card' : ''}>
              <Nav className="justify-content-end flex-grow-1 pe-3 gap-1">
                <Nav.Link className={active === 'hero' ? 'navbar-menuItem-active' : 'navbar-menuItem'} onClick={() => handleClick('hero')}>{userData ? "Caseworks" : "Home"}</Nav.Link>
                {!userData && <Nav.Link className={active === 'caseworking' ? 'navbar-menuItem-active' : 'navbar-menuItem'} onClick={() => handleClick('caseworking')}>Case Working</Nav.Link>}
                <Nav.Link className={active === 'liveclasses' ? 'navbar-menuItem-active' : 'navbar-menuItem'} onClick={() => handleClick('liveclasses')}>Live Classes</Nav.Link>
                {userData && <Nav.Link className={active === 'courses' ? 'navbar-menuItem-active' : 'navbar-menuItem'} onClick={() => handleClick('courses')}>Courses</Nav.Link>}
                {/* <Button className="navbar-button"></Button> */}
                <ButtonBasic text={userData ? 'Logout' : 'Login/Register'} onClick={handleLoginLogout} />
                {/* <button>button</button> */}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default PublicNavbar;
