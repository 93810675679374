import React from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import toast from 'react-hot-toast';
import { requestData2 } from '../utils/ApiRequests';
import Swal from 'sweetalert2'


function BookingForm1() {



    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        email: "",
        name: "",
        whatsapp_number: "",
        country: "",

        query: ""
    });
    const [acceptNewLetter, setAcceptNewLetter] = useState(true);


    const handleSubmit = async () => {
        const { email, name, whatsapp_number, query: message, country } = values;

        const pattern = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/g;

        const isValidEmail = pattern.test(email);
        if (!isValidEmail) {
            toast.error("Please enter a valid email");
            return
        }

        if (!name) {
            toast.error("Please enter a valid name");
            return
        }
        if (!whatsapp_number) {
            toast.error("Please enter a valid whatsapp number");
            return
        }


        if (!message) {
            toast.error("Please enter a valid message");
            return
        }

        const obj = {
            name,
            email:email.trim().toLowerCase(),
            phone: whatsapp_number,
            message,
            country: country
        }

        try {
            setLoading(true)
            const res = await requestData2("/auth/appointment", "POST", obj)
            setLoading(false)

            if(acceptNewLetter){
              const newLetterRes= await requestData2("/auth/newsletter","POST",{email:obj?.email});
              if(newLetterRes?.success){
                toast.success(newLetterRes?.message)
              }else{
                toast.error(newLetterRes?.error)
              }
            }

            if (res && res.success) {
                Swal.fire({
                    title: "Thank you",
                    text: res.message,
                    icon: "success"
                });

                setValues({
                    email: "",
                    name: "",
                    whatsapp_number: "",
                    country: "",
                    query: ""
                })
            } else if (res.error === "Duplicate Field value entered") {
                Swal.fire({
                    title: "Something went wrong",
                    text: "You have already submitted this form",
                    icon: "error"
                });
                setValues({
                    email: "",
                    name: "",
                    whatsapp_number: "",
                    country: "",
                    query: ""
                })

            } else {
                Swal.fire({
                    title: "Something went wrong",
                    text: res.error,
                    icon: "error"
                });
            }
        } catch (err) {
            console.log(err)

        }


    }



    return (
      <div className="d-flex justify-content-end align-items-center flex-column mt-3">
        <Card
          style={{
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <Card.Body>
            <Card.Title
              className="text-dark mb-3 text-center"
              style={{
                fontWeight: "700",
                fontSize: "1.5rem",
                color: "black",
              }}
            >
              Submit the form to talk to a Silva Instructor
            </Card.Title>
            <div
              style={{
                padding: "0px",
              }}
            >
              <Row className="gy-3 justify-content-center align-items-center">
                <Col xs={12}>
                  <label className="text-dark mb-1">Enter Full name</label>
                  <input
                    placeholder="enter full name"
                    type="text"
                    id="inputfield"
                    value={values.name}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        name: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <label className="text-dark mb-1">
                    Enter whatsapp number with country code
                  </label>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                    }}
                    placeholder="Enter country code and whatsapp number"
                    defaultCountry="usa"
                    value={values.whatsapp_number}
                    onChange={(phone, country) =>
                      setValues({
                        ...values,
                        whatsapp_number: phone,
                        country: country.country.name,
                      })
                    }
                  />
                </Col>
                <Col xs={12}>
                  <label className="text-dark mb-1">Enter Your Email</label>
                  <input
                    placeholder="enter your email"
                    type="email"
                    id="inputfield"
                    value={values.email}
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                </Col>

                <Col xs={12}>
                  <label className="text-dark mb-1">Enter Your Query</label>
                  <textarea
                    placeholder="enter your message"
                    id="textareanew"
                    rows={4}
                    cols={50}
                    value={values.query}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        query: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <Form.Check
                    type="checkbox"
                    label={
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Sign me up for The Silva Method's email newsletter with
                        powerful ideas on improving your life.
                      </p>
                    }
                    checked={acceptNewLetter}
                    onChange={() => setAcceptNewLetter(!acceptNewLetter)}
                  />
                </Col>
                <Col xs={12}>
                  <button
                    id="appointbtn"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? "...loading please wait" : "Submit"}
                  </button>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
}

export default BookingForm1


