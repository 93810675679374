import React, { useContext } from 'react'
import CustomHeader from '../../../components/CustomHeader'
import ParaComp from '../../../components/ParaComp'
import { AllContext } from '../../../context/AllContext'
import Footer from '../../../layouts/Footer'


function SilvaMethodDesc() {

  const {isMobile , isTablet}= useContext(AllContext)
  return (
    <div className='pt-5 about-silva-cta-container' style={{backgroundPositionY:(isMobile || isTablet)?'100px':''}}>
        <div className='container text-center py-5'>
            <CustomHeader title={'What is'} colored={'The Silva Method'}/>
            <ParaComp 
             data={[
                "Have you ever contemplated a life different from your own, not so excessively lavish but not strictly minimalistic either? How often have you found yourself comparing your circumstances to those of individuals who may not possess great wealth, yet radiate genuine happiness? It is intriguing to delve into the reasons why some people find contentment and joy with little, while others, despite seemingly having everything to be happy about, perpetually remain dissatisfied.",
               ]}
               white={false}
            />
            <div className='pt-5'>
              {/* <ButtonBasic text={'Know More'} style={{backgroundColor:'#f1c40f'}}/> */}
              <button className='caseworking-card-btn p-5 py-3' onClick={()=>window.open('https://silvamethod.com')}>Know More</button>
            </div>
            <div className='blank-height'></div>
        </div>
        <Footer/>
    </div>
  )
}

export default SilvaMethodDesc