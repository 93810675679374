import React, { useContext, useState } from "react";
import { Form, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { requestData2 } from "../../../utils/ApiRequests";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../../context/AllContext";

function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate= useNavigate();

  const {updateUser} = useContext(AllContext);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    if (!password) {
      toast.error("Please enter your password");
      return;
    }

    // console.log(email, password,'loginvalues')

    setLoading(true);

    const result = await requestData2("/auth/login", "POST", {
      email: email,
      password: password,
    });
    // console.log(result,'result');
    
    if(result.sucess){
      toast.success(result?.message);
      localStorage.setItem("silvacasetoken", JSON.stringify(result?.token));
      await updateUser(result?.token);
      navigate("/caseworking");
    }else{
      toast.error(result?.error)
    }
    // toast.promise(result, {
    //     loading: "Logging in...", // Message shown while promise is resolving
    //     success: (data) => {
    //       localStorage.setItem("silvacasetoken", JSON.stringify(data.token));
    //       return data.message; // Toast message on successful login
    //     },
    //     error: (error) => {
    //       return error.message || "An error occurred"; // Toast message on error
    //     },
    //   }).then(() => {
    //     navigate("/caseworking"); // Navigate upon successful login
    //   });
    setLoading(false);
  };

  return (
    <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
      <div
        id="radius-shape-1"
        className="position-absolute rounded-circle shadow-5-strong"
      ></div>
      <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

      <div className="card bg-glass">
        <div className="p-5 pb-0 d-flex justify-content-center align-items-center">
          <img src="/assets/img/silvamethod-logo.png" alt="SilvaLogo"/>
        </div>
        <div className="card-body px-4 py-5 px-md-5">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-3 text-start">
              <Form.Label className="m-1">Email address</Form.Label>
              <FormControl
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete={"newemail"}
                style={{ backgroundColor: "#f8f5fc", height: "50px" }}
              />
            </Form.Group>
            <Form.Group
              controlId="formBasicPassword"
              className="mb-3 text-start"
            >
              <Form.Label className="m-1">Password</Form.Label>
              <InputGroup>
                <FormControl
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete={"newpassword"}
                  style={{
                    borderRight: "none",
                    backgroundColor: "#f8f5fc",
                    height: "50px",
                  }}
                />
                <InputGroup.Text
                  style={{ cursor: "pointer", backgroundColor: "#e8f0fe" }}
                  onClick={handleTogglePassword}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible size={20} />
                  ) : (
                    <AiOutlineEye size={20} />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <p
              style={{
                color: "#7b14d4",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => setShowModal(true)}
            >
              Forgot Password?{" "}
            </p>

            <button className="benifits-card px-5 py-2 border-0 rounded mt-3">
              {loading ? (
                <Spinner animation="border" variant="light" size="sm"/>
              ) : (
                "Login"
              )}
            </button>
          </Form>
        </div>
      </div>
      <ForgotPasswordModal
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}

export default LoginForm;
