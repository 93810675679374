import React, { useContext, useState } from "react";
import {
  Modal,
  Container,
  Form,
  FormControl,
  Accordion,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import moment from "moment";
import { AllContext } from "../../../context/AllContext";
import { requestData2 } from "../../../utils/ApiRequests";

function PickupCase(props) {
  const [numberofCase, setNumberofCase] = useState("");
  const [caseType, setCasetype] = useState("recent");
  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);

  const { isDesktop, isTablet, isBigScreen } = useContext(AllContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!numberofCase || numberofCase === 0) {
      toast.error("Select number of cases (1 to 5) ");
      return;
    }

    const headers = {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("silvacasetoken")
      )}`,
    };

    setLoading(true);

    try {
      const res = await requestData2(
        "/private/getcases",
        "POST",
        { num: Number(numberofCase), type: caseType },
        headers
      );
      // console.log(res,'res')
      if (res.success) {
        toast.success("Data successfully retrieved");
        setCases(res.data);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log(error, "error");
    }

    setLoading(false);

    // console.log(numberofCase, caseType, "formdata");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="pickcase-modal"
    >
    <div
        className="align-self-end rounded-5 m-2"
        style={{backgroundColor:'#d801fb85',cursor: 'pointer',padding:'4px',display:'flex',justifyContent: 'center',alignItems: 'center'}}
        onClick={() => props.onHide()}
      >
        <IoClose  color="#ffffffa6" size={20} />
      </div>
      <Container
        className={`p-${
          isDesktop ? 5 : 3
        } pt-2 pb-3 d-flex justify-content-center justify-content-lg-start pickcase-first overflow-y-scroll`}
        style={{height:cases?.length?"":"auto"}}
      >
        <div style={{ width: isDesktop ? "60%" : "100%" }}>
          <h3>
            Pickup <span style={{ color: "#d801fb" }}>Cases</span>
          </h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-2">
              <Form.Label className="m-1">Number of cases (1to 5)</Form.Label>
              <FormControl
                type="tel"
                placeholder="Type here..."
                value={numberofCase}
                onChange={(e) => setNumberofCase(e.target.value)}
                style={{ backgroundColor: "#f8f5fc", height: "50px" }}
              />
            </Form.Group>
            <div
              className={`d-flex gap-${
                isDesktop || isTablet ? "5" : "2"
              } flex-wrap mt-3`}
            >
              <div>
                <input
                  type="radio"
                  id="recent"
                  name="casetype"
                  onChange={() => setCasetype("recent")}
                  checked={caseType === "recent"}
                />
                <label for="recent">Most recent case</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="random"
                  name="casetype"
                  onChange={() => setCasetype("random")}
                  checked={caseType === "random"}
                />
                <label for="random">Random case</label>
              </div>
            </div>
            <div>
              <button className="benifits-card px-5 py-2 border-0 rounded mt-4">
                {loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
          {cases?.length > 0 && (
            <div className="mt-5">
              <h3
                className={`d-${
                  isDesktop ? "flex gap-2" : ""
                } align-items-center`}
              >
                Total No of Case
                <div className="d-flex gap-1 align-items-center">
                  <span>Retrived</span>
                  <div
                    style={{
                      backgroundColor: "#ffd401",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                    }}
                  >
                    {cases?.length}
                  </div>
                </div>
              </h3>
            </div>
          )}
          <div className="pb-4">
          <Accordion defaultActiveKey="0">
            {cases?.map((el, i) => (
              <Accordion.Item eventKey={i} key={i} className="my-3">
                <Accordion.Header>
                  <div>
                    {isBigScreen ? (
                    <Row className="w-100 border-bottom pb-2">
                      <Col sm={6} md={6} lg={3}>
                        <div className="py-1 border-end border-dark">
                          <p>Patient Name</p>
                          <b>{el?.name}</b>
                        </div>
                      </Col>
                      <Col sm={6} md={6} lg={3}>
                        <div className="py-1 border-end border-dark">
                          <p>Gender</p>
                          <b className="text-capitalize">{el?.sex}</b>
                        </div>
                      </Col>
                      <Col sm={6} md={6} lg={2}>
                        <div className="py-1 border-end border-dark">
                          <p>Age</p>
                          <b>{el?.age}</b>
                        </div>
                      </Col>
                      <Col sm={6} md={6} lg={4}>
                        <div className="py-1">
                          <p>Posted On:</p>
                          <b>{moment(el?.createdAt).format("YYYY-MM-DD")}</b>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div
                      className="d-flex overflow-scroll"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <div className="p-3 py-1 border-end border-dark">
                        <p>Patient Name</p>
                        <b>{el?.name}</b>
                      </div>
                      <div className="p-3 py-1 border-end border-dark">
                        <p>Gender</p>
                        <b className="text-capitalize">{el?.sex}</b>
                      </div>
                      <div className="p-3 py-1 border-end border-dark">
                        <p>Age</p>
                        <b>{el?.age}</b>
                      </div>
                      <div className="p-2">
                        <p>Posted On:</p>
                        <b>{moment(el?.createdAt).format("YYYY-MM-DD")}</b>
                      </div>
                    </div>
                  )}
                  </div>
                    <div className="border-dark w-100 m-auto p-2 d-flex flex-column flex-wrap">
                      <p>Address</p>
                      <b>{el?.city || "City not provided"}, {el?.state || "State not provided"} ,{el?.country || "Country not provided"} </b>
                    </div>
                </Accordion.Header>
                <Accordion.Body className="p-0 m-0">
                  <Container>
                    <div className="border-bottom border-dark w-100 m-auto py-2">
                      <p>Medical Diagnosis (if known)</p>
                      <b>{el?.medicalDiagnosis}</b>
                    </div>
                    <div className="border-bottom border-dark w-100 m-auto py-2">
                      <p>
                        {" "}
                        Symptoms as perceived by the subject; describe all
                        health problems and specific location,which side
                        (left,right) etc. Use lay terminology only; no medical
                        terminology in this section
                      </p>
                      <b>{el?.symptoms}</b>
                    </div>
                    <div className="border-bottom border-dark w-100 m-auto py-2">
                      <p>
                        Past Operations/Accidents (with approximate dates if
                        known, month/year is fine)
                      </p>
                      <b>{el?.pastOperations}</b>
                    </div>
                    <div className="border-bottom border-dark w-100 m-auto py-2">
                      <p>Dominant Personality Characteristics</p>
                      <b>{el?.personalityCharacteristics}</b>
                    </div>
                    <div className="border-bottom border-dark w-100 m-auto py-2">
                      <p>
                        {" "}
                        General Description: height (ft./in.), weight (lbs.),
                        eye color, hair color, complexion, glasses?, smoker?,
                        occupation
                      </p>
                      <b>{el?.desc}</b>
                    </div>
                    <div className="border-bottom border-dark w-100 m-auto py-2">
                      <p>Any other Relevant Information not listed above</p>
                      <b>{el?.otherDesc}</b>
                    </div>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        </div>
      </Container>
      {/* <Container className="px-5 py-3">
        
      </Container> */}
    </Modal>
  );
}

export default PickupCase;
