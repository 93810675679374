
export const REACT_SILVA_BASE_URL = process.env.REACT_APP_SILVA_URI;

export const REACT_APP_SERVER_URI = process.env.REACT_APP_SERVER_URI;

export const requestData = async function (path, method = "POST", body) {

    // console.log(token3,"tokensending")
    //console.log(body);
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");
    // const st_id = localStorage.getItem("token")
    // const country = localStorage.getItem("countryname");
    // const currency = localStorage.getItem("currency")
 

    // if (country) {
    //     myHeaders.append("country", country.toString())
    // }

    // if (currency) {
    //     myHeaders.append("currency", currency.toString())

    // }
    // if (st_id) {
    //     myHeaders.append("studentid", st_id.toString())
    // }


    const formData = new FormData();

    const keys = Object.keys(body);

    if (keys.length > 0) {
        for (let key in body) {
            formData.append(key.toString(), body[key])
        }
    }


    // console.log(typeof parseInt(st_id),st_id,"sudentid")

    const options = {
        method: method,
        headers: myHeaders,
        body: formData
    }

    const res = await fetch(`${REACT_SILVA_BASE_URL}${path}`, options)
    const data = await res.json();
    return data
}


export const requestData2 = async function (path, method = "POST", bodyObj, headers) {
    let requestOptions = {
        method: method,
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            ...headers
        }
    };

    // Only include body if method is not GET
    if (method !== "GET") {
        requestOptions.body = JSON.stringify(bodyObj);
    }

    const response = await fetch(`${REACT_APP_SERVER_URI}${path}`, requestOptions);
    const data = await response.json();
    return data;
}




