import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MoonLoader from "react-spinners/MoonLoader";
import CourseCard from "../../../components/CourseCard";
import { AllContext } from "../../../context/AllContext";
import { requestData } from "../../../utils/ApiRequests";
import CustomHeader from "../../../components/CustomHeader";


function Courses() {
  const { isDesktop, isTablet,isBigScreen } = useContext(AllContext);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCourses = async () => {
    setLoading(true);
    const res = await requestData("/latestCourseList", "POST", {
      start_index: 0,
      no_of_records: 100,
    });
    // console.log(res, "courseList");
    if (res.error === false) {
      setCourses(res?.data?.filter((course) => course?.course_id !=="silva-method-complete-course-with-live-interactive-program")?.sort((a,b)=>a?.precedence-b?.precedence));
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <div className="position-relative pt-2">
      {/* <Heading  colored={'Home Study Programs'} subtitle={'Silva Method'}/> */}

      <div className="mt-5">
        <CustomHeader title={"Silva"} colored={"Method"} />
        <h3
          className="text-center lh-1 m-0"
          style={{ fontSize: isDesktop ? "25px" : isTablet ? "20px" : "15px" }}
        >
          {"Home Study Programs"}
        </h3>
      </div>
      {isBigScreen && <div style={{position:'absolute', top:0,left:300}}>
            <img src="/assets/img/bird2.webp" alt="" style={{width:'300px',height:'170px'}}/>
        </div>}
      <div className="container my-3 courses">
        {loading ? (
          <div className="d-flex flex-column w-100 align-items-center justify-content-center p-5">
            <MoonLoader loading={loading} size={100} color="#250f74" />
          </div>
        ) : (
          <Row className="gy-3 justify-content-center mb-5">
            {courses.map((course, i) => (
              <Col sm={1} md={6} lg={4} xl={3}>
                <CourseCard data={course} key={i} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
}

export default Courses;
