import React, { useContext } from 'react'
import { AllContext } from '../../context/AllContext';
import { Navigate, Outlet } from 'react-router-dom';

function PublicRoute() {

    const {userData}= useContext(AllContext);

    if(userData){
        return <Navigate to={"/caseworking"}/>;
    }

  return (
    <>
        <Outlet/>
    </>
  )
}

export default PublicRoute