import React, { useContext } from 'react'
import { AllContext } from '../context/AllContext';

function CustomHeader({title, colored,small=false,boxed=false,boxedtext="",white=false,centered=true}) {
    const {isDesktop,isTablet} = useContext(AllContext);

    return (
        <h1
            style={{
                fontWeight: "bolder",
                fontSize: isDesktop ?"45px" : isTablet ?"20px" :"19px",
                textAlign: centered?"center":"start",
                color: white ? "white" : "#000"
            }}
        >

            {title} <span style={{ color: "#d801fb", fontWeight: "800"}}>{colored}</span>{" "}
            {
                boxed && (
                    <span style={{
                        // padding: "10px 14px", color: "#d801fb",
                        border: "none", backgroundColor: "white",
                        fontSize: isDesktop ? "45px" : isTablet ?"20px" :"19px",
                        fontWeight: "bolder",
                        borderRadius: "8px"
                    }}>
                        {
                            boxedtext
                        }
                    </span>
                )
            }
        </h1>
    )
}

export default CustomHeader