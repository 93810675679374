import React, { useState } from "react";
import { Modal, Container, Form, FormControl, Spinner} from "react-bootstrap";
import toast from "react-hot-toast";
import { IoMdCloseCircle } from "react-icons/io";
import { requestData2 } from "../../../utils/ApiRequests";

function ForgotPasswordModal(props) {
  const [email, setEmail] = useState('');
  const [loading,setLoading] = useState(false);

  const handleSubmit = async(e) => {
    e.preventDefault();

     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // console.log(email,'email...')

    setLoading(true)
    try {
      const res = await requestData2("/auth/forgotpassword","POST",{email});

      console.log(res,'response')
      if(res.success){
        toast.success("Reset password link sent to your email, be sure to check spam folder");
      }else{
        toast.error(res.error || "Something went wrong. Please try again after something")
      }
      setEmail("");
      props.onHide();
  } catch (err) {
    // console.log(err,'err')
    toast.error(err?.error || "Something went wrong. Please try again later")
  }
  setLoading(false)
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="forgot-password-modal"
    >
      <div className="d-flex justify-content-end p-1" onClick={() => props.onHide()}>
        <IoMdCloseCircle color="grey" size={30} />
      </div>
      <Container className="p-5 pt-2 d-flex justify-content-center justify-content-lg-start">
        <div style={{ width:  '100%' }}>
          <h3><span style={{ color: "#d801fb", }}>Forgot Password ?</span></h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label className="m-1 fw-bold">Enter your email </Form.Label>
              <FormControl
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete={"newemail"}
                style={{ backgroundColor: '#f8f5fc', height: '50px' }}
              />
            </Form.Group>

            <button className="benifits-card px-5 py-2 border-0 rounded mt-3">
            {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Submit"
              )}
            </button>
          </Form>
        </div>
      </Container>
    </Modal>
  );
}

export default ForgotPasswordModal;
