import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../components/Button";
import { MdLooksOne,MdLooksTwo ,MdLooks3 } from "react-icons/md";
import { AllContext } from "../../../context/AllContext";
function FirstHero() {

  const {isMobile}= useContext(AllContext);

  const handleButtonClick = () => {
    const element = document.querySelector(`.${"caseworking"}`);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
    // console.log('clicked')
  };
  const benifits = [
    {
      id: 1,
      text:<p>Get lifetime access to the complete Silva Method Audio course with live interactive sessions with the experts.</p>,
      icon: <MdLooksOne size={20}/>
    },
    {
      id: 2,
      text: <p>Became a member of our global community and directly communicate with <span className="fw-bold text-decoration-underline">Diana Silva</span>, the president of the Silva Method.</p>,
      icon:<MdLooksTwo size={20}/>
    },
    {
      id: 3,
      text: <p>Receive free doubt-clearing sessions from certified instructors every week.</p>,
      icon: <MdLooks3 size={20}/>
    },
  ];
  return (
    <div className="benifits-card" id="hero">
      {/* <img src="/assets/img/banner.webp" alt="" className='w-100 vh-100'/>
        <div className='position-absolute w-100 start-0 top-50 translate-middle-y px-0 px-lg-5'>
            <div className='container-fluid'>
                <div className='w-25'>
                    <h3 className='fs-4 text-white'>Welcome to</h3>
                    <h1 style={{fontSize:'3rem', fontWeight:'bold', color:'white'}}>Silva Method Case Working Portal</h1>
                    <p className='text-white'>The most valuable technique in the Silva Method is the Caseworking Technique.</p>
                    <Button title={'Submit Your Case'}/>
                </div>
            </div>
        </div> */}
      <div className="container py-5">
        <Row>
          <Col className="d-flex align-items-center" sm={12} md={12} lg={5}>
            <div>
              <h3 className="h3 text-white">Welcome to</h3>
              <h1 style={{ fontSize:isMobile? "30px": "50px" }}>Silva Method Case</h1>
              <h1 style={{ fontSize:isMobile? "30px": "50px" }}>Working Portal</h1>
              <p className="text-white my-4 fw-bold">
                Enjoy Exclusive Perks with Your Silva Method Complete Course
                Purchase.
              </p>
              <div className="mb-4">
                {benifits?.map((benifit, i) => (
                  <div className="d-flex gap-3">
                    <div>
                      {benifit.icon}
                    </div>
                    <div>{benifit.text}</div>
                  </div>
                ))}
              </div>
              <div>
                <Button
                  title={"Submit Your Case"}
                  onClick={handleButtonClick}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={7}>
            <div>
              <img
                src="/assets/img/silva cases-banner.webp"
                alt=""
                className="w-100"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default FirstHero;