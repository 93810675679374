import React, { useContext } from 'react'
import Heading from '../../../components/Heading';
import Tabs from '../../../components/Tabs';
import { AllContext } from '../../../context/AllContext';
import { useNavigate } from 'react-router-dom';




function CaseWorking() {

    const {isBigScreen}= useContext(AllContext);
    const navigate= useNavigate();

    const practitioner=[
        {
            id:0,
            image:'/assets/img/user.webp',
            title:'Create your account',
            content:"To create an account proceed through the following steps:",
            points:[
                "Click on the 'Create Account' button.",
                "Then click on the “Register” Button.",
                "Complete the registration process.",
                "Enter the verification code provided.",
                "Submit the required information. " ,   
                "Log in using your newly created credentials."
            ],
            btnText:'Create Account',
            onclick:()=> navigate('/authenticate'),
        },
        {
            id:1,
            image:'/assets/img/uploading.webp',
            title:'Submit your case',
            content:"The Silva Graduates can heal you even if they're far away. They can send healing energy to you. If you need healing without any medication then fill in all the necessary details and submit your case. The Silva graduates will help you.",
            btnText:'Submit Cases',
            onclick:()=> navigate('/authenticate'),
        }
    ];

    const healing=[
        {
            id:0,
            image:'/assets/img/user.webp',
            title:'Create your account',
            content:"To create an account proceed through the following steps:",
            points:[
                "Click on the 'Create Account' button.",
                "Then click on the “Register” Button.",
                "Complete the registration process.",
                "Enter the verification code provided.",
                "Submit the required information. " ,   
                "Log in using your newly created credentials."
            ],
            btnText:'Create Account',
            onclick:()=> navigate('/authenticate'),
        },
        {
            id:1,
            image:'/assets/img/download.webp',
            title:'Retrieve Cases',
            content:"As a Silva graduate if you want to improve your ESP and and extend your assistance to those in need, select specific cases and channel healing energy toward them.",
            btnText:'Retrieve Cases',
            onclick:()=> navigate('/authenticate'),
        }
    ];

    const options=[
        {
            id:1,
            text:'Seeking remote healing',
            card: practitioner
            
        },
        {
            id:2,
            text:'For Silva Graduates',
            card: healing
        },
    ];

    

  return (
    <div className='caseworking py-5 position-relative'>
        {/* <div className='pt-4'>
            <h3 className='text-center lh-1 m-0'>How Silva Method</h3>
            <CustomHeader title={'Caseworking'} colored={'Works'}/>
        </div> */}
        {isBigScreen && <div style={{position:'absolute', top:0,right:0}}>
            <img src="/assets/img/tree.webp" alt="" style={{width:'500px',height:'400px'}}/>
        </div>}
        {isBigScreen && <div style={{position:'absolute', top:150,left:100}}>
            <img src="/assets/img/birds.webp" alt="" style={{width:'400px',height:'250px'}}/>
        </div>}
        <div>
        <Heading title={'Caseworking'} subtitle={'How Silva Method'} colored={'Works'}/>
        </div>
        <div>
            <Tabs tablist={options}/>
        </div>
    </div>
  )
}

export default CaseWorking