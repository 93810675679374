import React from "react";
import { Image, Card } from "react-bootstrap";
import SilvaLogo from "./Untitled design/Image20240307124930.png";
import Image1 from "./Untitled design/1.jpg";
import Image2 from "./Untitled design/2.jpg";
import Image3 from "./Untitled design/3.jpg";
import Image4 from "./Untitled design/4.jpg";

function CustomCard({
  index,
  ins_image,
  title,
  ins_name,
  date,
  language,
  mode,
  borderRadiusAll = false,
}) {
  const images = [Image1, Image2, Image3, Image4];

  function getCyclicImages(images, count) {
    const cyclicImages = [];
    for (let i = 0; i < count; i++) {
      cyclicImages.push(images[i % images.length]);
    }
    return cyclicImages;
  }

  const repeatedImages = getCyclicImages(images, 4);

  // console.log(repeatedImages,index,'bgdata');

  return (
    <Card
      style={{
        backgroundImage: `url(${
          repeatedImages[index % repeatedImages.length]
        })`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        marginBottom: 10,
        borderRadius: "15px 15px 0 0",
        width: "100%",
        objectFit: "cover",
      }}
      className="d-flex justify-content-center align-items-center text-center pt-2 m-0"
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="text-black m-0 bg-white"
            style={{
              fontSize: "14px",
              borderRadius: "4px",
              padding: "4px 12px",
              fontWeight: "600",
            }}
          >
            Register Now!
          </div>

          <Image
            src={SilvaLogo}
            alt="silvalogo"
            style={{
              width: 50,
              height: 50,
              borderRadius: "50%",
            }}
          />
        </div>
        <Image
          src={ins_image}
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            objectPosition: "top",
            objectFit: "cover",
            margin: "auto",
          }}
        />
        <h5 className="text-white text-warning text-uppercase border-bottom border-white py-2">
          {title}
        </h5>
        <h5 className="dancing-script my-2">{ins_name}</h5>
        <h6 className="text-white text-uppercase">{date}</h6>
      </div>
      <div
        className="d-flex justify-content-between align-items-center w-100 p-3 py-2"
        style={{
          backgroundColor: "#581469",
        }}
      >
        <b className="text-white text-uppercase m-0">Lang: {language}</b>
        <b
          className="text-transparent text-uppercase p-2 px-3 bg-white rounded-2 m-0"
          style={{ fontSize: "10px" }}
        >
          {mode}
        </b>
      </div>
    </Card>
  );
}

export default CustomCard;
