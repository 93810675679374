import React, { useState } from 'react'
import PublicNavbar from '../../../layouts/Navbar'
import CaseHeroSection from './HeroSection'
import Footer from '../../../layouts/Footer'
import PickupCase from './PickUpCase';
import SubmitCase from './SubmitCase';
import { Toaster } from 'react-hot-toast';
import ViewCase from './ViewCase';


function AuthenticatedHome() {

  const [showAuthModal,setShowAuthModal]= useState({state:false,name:''});

  return (
    <div>
      <div style={{backgroundImage:`url('/assets/img/user-home-bg.jpg')`,backgroundPosition:'center',backgroundSize:'cover'}}>
      <PublicNavbar/>
      <CaseHeroSection showAuthModal={showAuthModal} setShowAuthModal={setShowAuthModal}/>
      <Footer />
      </div>
      <PickupCase show={showAuthModal.state && showAuthModal.name==='pickupcase'}  onHide={() => setShowAuthModal({state:false,name:''})}/>
      <SubmitCase show={showAuthModal.state && showAuthModal.name==='submitcase'}  onHide={() => setShowAuthModal({state:false,name:''})}/>
      <ViewCase show={showAuthModal.state && showAuthModal.name==='viewcase'}  onHide={() => setShowAuthModal({state:false,name:''})}/>
      <Toaster/>
    </div>
  )
}

export default AuthenticatedHome