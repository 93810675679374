import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { AllContext } from "../../../context/AllContext";

function BenifitSection() {


  const {isDesktop,isBigScreen}= useContext(AllContext)

  const benifits = [
    {
      id: 1,
      text: "8 original courses.",
    },
    {
      id: 2,
      text: "280+ lessons.",
    },
    {
      id: 3,
      text: "85+ exercises.",
    },
    {
      id: 4,
      text: "3+ live classes every month.",
    },
    {
      id: 5,
      text: "Free doubt-clearing sessions.",
    },
  ];

  return (
    <div className="benifits_image_bg mt-3" style={{ padding: "80px 0px" }}>
      <div className="container">
        <Row className="d-flex justify-content-center align-items-center">
          <Col sm={12} md={12} lg={6}>
            <img
              src="/assets/img/benifits_image.png"
              alt=""
              style={{ width:(isBigScreen)? "80%":"100%", height:(isBigScreen)? "80%":"100%" }}
            />
          </Col>
          <Col sm={12} md={12} lg={6} className={"px-2"}>
            <div className="container">
              <h4 className="text-white">
                The Silva Method Newly Launched Curriculum
              </h4>
              <b className="text-white mt-3">
                Silva Method Complete Course + Live Interactive Classes
              </b>
              <p className="text-white my-3">
                Get lifetime access to all 100% original Silva Method Courses
                with Live interactive sessions from the experts. Enroll now for
                an enriching journey toward inner harmony and fulfillment.
              </p>
              <p className="text-white my-3">Additionally, enjoy these perks:</p>
            </div>
            <div className="mt-3 my-5 mx-2">
              {benifits.map((benifit, i) => (
                <div className="d-flex align-items-center gap-2 mt-2">
                  <IoMdCheckmarkCircle color="white" size={20} />
                  <p className="text-start text-white" key={i}>
                    {benifit.text}
                  </p>
                </div>
              ))}
            </div>
            <button
              className="p-3 px-5 border border-0 rounded-3 fw-bold mx-3"
              style={{ backgroundColor: "#ffd401" }}
              onClick={()=>window.open('https://silvamethod.com/store/course/combo-plan')}
            >
              Get Access Now
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BenifitSection;
