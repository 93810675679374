import React, { useContext, useState } from 'react'
import { AllContext } from '../../../context/AllContext';
import { Card, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import toast, { Toaster } from 'react-hot-toast';
import { requestData2 } from '../../../utils/ApiRequests';
import { useNavigate, useParams } from 'react-router-dom';

function ResetHero() {

    const {isMobile } = useContext(AllContext);
    const [loading,setLoading]=  useState(false);
    const [values,setValues] = useState({
        password: "",
        confirmpass: ""
    });
    const [showPassword, setShowPassword] = useState({ status: false, name: "" });
    const navigate= useNavigate();
    const params= useParams();

    const handleTogglePassword = (name) => {
        if (showPassword.name === "") {
          setShowPassword({ status: true, name: name });
        } else {
          setShowPassword({ status: false, name: "" });
        }
      };


    const handleSubmit=async(e)=>{
        e.preventDefault();

        // console.log(params.token)

        if (!values.password || !values.confirmpass) {
            toast.error("Please enter your password and confirm password");
            return
        }
        if (values.password !== values.confirmpass) {
            toast.error("Password and confirm password does not match");
            return
        }

        setLoading(true)
        try {
            const res = await requestData2(`/auth/passwordreset/${params.token}`,"PUT",{ password: values.password });
            if(res.success){
                toast.success("Password changed successfully. Login with your new password");
                navigate("/authenticate");
            }else{
                toast.error(res.error)
            }
            // console.log(res,'response');
        } catch (err) {
            toast.error(err.error)
        }
        
        setLoading(false)
    }

  return (
    <div
    className={`${
        isMobile ? "vh-100" : "vh-100"
      } w-100 d-flex justify-content-center align-items-center`}
    >
        <div className="container d-flex justify-content-center align-items-center">
          <Card
            style={{
              width: isMobile ? "100%" : "50%",
              padding: isMobile ? "10px" : "20px",
            }}
          >
            <div>
              <h3
                class="my-3 display-5 fw-bold ls-tight"
                style={{ color: "black" }}
              >
                Reset 
                <span style={{ color: "#d901fc" }}> {"Password"}</span>
              </h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="resetPassword" className="mb-3 text-start">
                  <Form.Label className="m-1 fw-bold">Password</Form.Label>
                  <InputGroup>
                    <FormControl
                      type={
                        showPassword.status && showPassword.name === "password"
                          ? "text"
                          : "password"
                      }
                      placeholder="Enter New Password"
                      value={values.password}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          password: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                    <InputGroup.Text
                      style={{ cursor: "pointer", backgroundColor: "#f8f5fc" }}
                      onClick={() => handleTogglePassword("password")}
                    >
                      {showPassword.status &&
                      showPassword.name === "password" ? (
                        <AiOutlineEyeInvisible size={20} />
                      ) : (
                        <AiOutlineEye size={20} />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  controlId="resetconfirmpassword"
                  className="mb-3 text-start"
                >
                  <Form.Label className="m-1 fw-bold">Confirm Password</Form.Label>
                  <InputGroup>
                    <FormControl
                      type={
                        showPassword.status &&
                        showPassword.name === "confirmpassword"
                          ? "text"
                          : "password"
                      }
                      placeholder="Re-enter new password"
                      value={values.confirmpass}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          confirmpass: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                    <InputGroup.Text
                      style={{ cursor: "pointer", backgroundColor: "#f8f5fc" }}
                      onClick={() => handleTogglePassword("confirmpassword")}
                    >
                      {showPassword.status &&
                      showPassword.name === "confirmpassword" ? (
                        <AiOutlineEyeInvisible size={20} />
                      ) : (
                        <AiOutlineEye size={20} />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <button className="benifits-card px-5 py-3 border-0 rounded mt-2 me-3">
                  {loading?  (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Change Password"
                  )}
                </button>
              </Form>
            </div>
          </Card>
        </div>
        <Toaster/>
    </div>
  )
}

export default ResetHero