import { useContext } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { AllContext } from "../context/AllContext";
import { FaAngleDoubleRight } from "react-icons/fa";

function Tabs({ tablist }) {
  const { isMobile } = useContext(AllContext);

  return (
    <div className="container">
      <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="flex-row justify-content-center">
              <div
                className={`d-flex ${
                  isMobile ? "flex-column" : ""
                } p-1 rounded-3 m-3 bg-white mb-5 mt-4 border-black`}
              >
                {tablist.map((tab, i) => (
                  <Nav.Item key={i}>
                    <Nav.Link
                      eventKey={tab.id}
                      className="p-3 px-5 rounded-3 fw-bold"
                    >
                      {tab.text}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </div>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              {tablist.map((tab, i) => (
                <Tab.Pane
                  eventKey={tab.id}
                  key={i}
                  style={{ maxWidth: "800px", margin: "auto" }}
                >
                  <Row>
                    {tab?.card?.map((c, i) => (
                      <Col
                        key={i}
                        style={{ marginTop: "80px", marginBottom: "40px" }}
                        lg={6}
                      >
                        <Card
                          className="main-caseworking-container"
                          style={{
                            height: "100%",
                            borderRadius: "15px",
                            padding: "2.5rem",
                          }}
                        >
                          <div className="caseworking-card">
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "#282282",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={c.image}
                                alt=""
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          {/* <div style={{padding:'10px', backgroundColor:'#282282', width:'120px', height:'120px',borderRadius:'50%',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                            <img src={c.image} alt="" style={{width:'80px',height:'80px',objectFit:'contain',objectPosition:'center'}}/>
                        </div> */}
                          <div className="d-flex justify-content-center align-items-center mt-5">
                            <div>
                              <h3 style={{ fontWeight: "bold" }}>{c.title}</h3>
                              <p className="my-3">{c.content}</p>
                              {c?.points?.map((point, i) => (
                                <div className="d-flex align-items-start gap-2">
                                  <FaAngleDoubleRight /> 
                                  <p>{point}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              bottom: "-20px",
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                          >
                            <button
                              className={`caseworking-card-btn px-${
                                isMobile ? "4" : "2"
                              }`}
                              onClick={() => c.onclick()}
                            >
                              {c.btnText}
                            </button>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default Tabs;
