import React, { useContext } from "react";
import { AllContext } from "../../../context/AllContext";

function WhatsAppQR() {
  const { isDesktop } = useContext(AllContext);

  return (
   <div>
      {
        (isDesktop)?
        <img src="assets/img/banner-img.webp" 
        onClick={() => {
          const whatsappGroupURL =
            "https://chat.whatsapp.com/I9MyDpvUlZVDLqPMAoIIxd";
          window.open(whatsappGroupURL);
        }}
        style={{cursor:'pointer',width:'100%'}}
        alt="desktop-whatsapp-baner"
        />:
        <img src="assets/img/banner-img-for-mobile.png"  
        onClick={() => {
          const whatsappGroupURL =
            "https://chat.whatsapp.com/I9MyDpvUlZVDLqPMAoIIxd";
          window.open(whatsappGroupURL);
        }}
        style={{cursor:'pointer',width:'100%'}}
        alt="mobile-whatsapp-baner"
        />
      }
   </div>
  );
}

export default WhatsAppQR;
