import React, { useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AllContext } from "../context/AllContext";
import { ImQuotesRight } from "react-icons/im";

function SliderCard({ data }) {
  const { isDesktop, isMobile } = useContext(AllContext);

  return (
    // <Card className='p-5' style={{width:isDesktop?'95%':'98%',height:isMobile?'600px':'300px',borderRadius:'15px'}}>
    // <div className='d-flex gap-4'>
    //  <div className='image-circle'>
    //   <img src={data?.image || '/assets/img/no_image.jpg'} alt="" style={{width:'auto',height:'100%',objectFit:'cover',borderRadius:'100px'}}/>
    //  </div>
    //  <div>
    //      <h5 className='m-0'>{data?.name}</h5>
    //      <p style={{padding:' 0px 10px', backgroundColor:'#f5f6fa',fontWeight:'500',borderRadius:'5px',display:'inline-block'}} className='my-2 mb-3'>{data?.designation}</p>
    //  </div>
    // </div>
    // <div className='ps-5'>
    //  <div className='px-3 ps-5 py-2'>
    //  <p>{data?.comment}</p>
    //  </div>
    //  <div className='d-flex justify-content-end'>
    //  <ImQuotesRight color='#faecff' size={50}/>
    //  </div>
    // </div>
    // </Card>
    <Card
      className="p-5"
      style={{
        width: isDesktop ? "95%" : "98%",
        height: isMobile ? "500px" : "300px",
        borderRadius: "15px",
      }}
    >
      <Row>
        <Col sm={12} md={2} lg={2} xl={1}>
          <div className="image-circle">
            <img
              src={data?.image || "/assets/img/no_image.jpg"}
              alt=""
              style={{
                width: "auto",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100px",
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={10} lg={10} xl={11}>
          <div>
            <h5 className="m-0">{data?.name}</h5>
            <p
              style={{
                padding: " 0px 10px",
                backgroundColor: "#f5f6fa",
                fontWeight: "500",
                borderRadius: "5px",
                display: "inline-block",
              }}
              className="my-2 mb-3"
            >
              {data?.designation}
            </p>
          </div>
          <div>
            <p>{data?.comment}</p>
          </div>
          <div className="d-flex justify-content-end">
            <ImQuotesRight color="#faecff" size={50} />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default SliderCard;
