import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import BookingForm1 from "../../../components/BookingForm";
import { AllContext } from "../../../context/AllContext";
import { Toaster } from "react-hot-toast";

function ContactSection() {

  const { isMobile } = useContext(AllContext);

  return (
    <div className="contact_bg">
      <div className="container">
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6}>
            <h2
              className="text-light"
              style={{
                fontSize: isMobile ? "1.5rem" : "3rem",
                lineHeight: isMobile ? "1.5rem" : "3rem",
              }}
            >
              Still Confused?
            </h2>
            <h4
              className="text-light"
              style={{
                fontSize: "1.5rem",
              }}
            >
              Talk to an Instructor for{" "}
              <span
                style={{
                  color: "#ffcc00",
                }}
              >
                FREE!
              </span>
            </h4>
            <p
              className="text-light"
              style={{
                fontFamily: "Poppins",
              }}
            >
              Dear Silva Cases user, If you find yourself still unsure &
              confused about the Silva Method and its Original Courses, and how
              they can swiftly bring positive transformations to your life,
              worry not. You now have the opportunity to engage in a free
              conversation with an Expert Silva Method Instructor. Simply fill
              in the form with your details and wait for your call with the
              instructor. <b>- Master your mind, Master your life</b>.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <BookingForm1 />
          </Col>
        </Row>
      </div>
      <Toaster/>
    </div>
  );
}

export default ContactSection;
