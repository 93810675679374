import { useMediaQuery } from "react-responsive";
import { requestData2 } from "../utils/ApiRequests";

const { createContext, useState } = require("react");

export const AllContext = createContext();

export const AllContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });

  const updateUser = async (userToken) => {
    const response = await requestData2(
      "/private/getProfile",
      "GET",
      {},
      {
        Authorization: `Bearer ${
          userToken || JSON.parse(localStorage.getItem("silvacasetoken"))
        }`,
      }
    );
    if (response?.success) {
      setUserData(response.data);
    }
  };

  const handleLogout = ()=>{
    localStorage.removeItem("silvacasetoken");
    setUserData(null);
 } 

  const values = {
    userData,
    setUserData,
    isDesktop,
    isTablet,
    isMobile,
    isBigScreen,
    updateUser,
    handleLogout
  };

  return <AllContext.Provider value={values}>{children}</AllContext.Provider>;
};
