import React, { useContext, useState } from "react";
import {
  Modal,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import { AllContext } from "../../../context/AllContext";
import { allcodes } from "../../../utils/allcodes";
import { requestData2 } from "../../../utils/ApiRequests";

function SubmitCase(props) {
  const [submitCaseValues, setSubmitCaseValues] = useState({
    name: "",
    age: "",
    sex: "male",
    country: "",
    city: "",
    state: "",
    medicalDiagnosis: "",
    symptoms: "",
    pastOperations: "",
    personalityCharacteristics: "",
    desc: "",
    otherDesc: "",
  });
  const [loading,setLoading] = useState(false);
  const [termAccepted, setTermAccepted] = useState(false);

  const { isDesktop } = useContext(AllContext);



  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);

    const { name, age, sex, country } = submitCaseValues;

    if (!name) {
      toast.error("Please enter your name");
      return;
    }
    if (!age) {
      toast.error("Please enter your age");
      return;
    }
    if (!sex) {
      toast.error("Please select your sex");
      return;
    }
    if (!country) {
      toast.error("Please select your country");
      return;
    }

    if (name.length < 3 || name.length > 20) {
      toast.error("Please enter a valid name");
      return;
    }
    if (parseInt(age) < 1 || parseInt(age) > 150) {
      toast.error("Please enter a valid age");
      return;
    }

    if (!termAccepted) {
      toast.error("Accept terms & conditions to continue!");
      return;
    };

    // console.log(submitCaseValues,'submitCaseValues');
    const submitCaseResult = await requestData2('/private/submitcase','POST',submitCaseValues,{
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("silvacasetoken"))}`
    });

    // console.log(submitCaseResult,'sre')
    if(submitCaseResult?.success){
        toast.success(submitCaseResult?.message);
        setSubmitCaseValues({
          name: "",
          age: "",
          sex: "male",
          country: "",
          city: "",
          state: "",
          medicalDiagnosis: "",
          symptoms: "",
          pastOperations: "",
          personalityCharacteristics: "",
          desc: "",
          otherDesc: "",
        });
        props.onHide();
    }else{
        toast.error(submitCaseResult?.error)
    }
    setLoading(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="submit-modal"
      // style={{backgroundImage:`url('/assets/img/sumbitcase-bg.png')`}}
    >
      {/* <h1>Submit</h1> */}
      <div
        className="align-self-end rounded-5 m-2"
        style={{backgroundColor:'#d801fb85',cursor: 'pointer',padding:'4px',display:'flex',justifyContent: 'center',alignItems: 'center'}}
        onClick={() => props.onHide()}
      >
        <IoClose  color="#ffffffa6" size={20} />
      </div>
      <Container className="p-5 pt-2 d-flex justify-content-center justify-content-lg-start">
        <div
          style={{
            width: isDesktop ? "50%" : "100%",
            height: isDesktop ? "100%" : "400px",
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: 0,
          }}
        >
          <h3 className="mb-3">
            Submit Your Case for{" "}
            <span style={{ color: "#d801fb" }}>Healing</span>
          </h3>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group controlId="Name" className="mb-3">
                  <Form.Label className="m-1 fw-bold">Full Name</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Enter name"
                    value={submitCaseValues.name}
                    onChange={(e) =>
                      setSubmitCaseValues({
                        ...submitCaseValues,
                        name: e.target.value,
                      })
                    }
                    autoComplete={false}
                    style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group controlId="age" className="mb-3">
                  <Form.Label className="m-1 fw-bold">Age</Form.Label>
                  <FormControl
                    type="age"
                    placeholder="Enter age"
                    value={submitCaseValues?.age}
                    onChange={(e) =>
                      setSubmitCaseValues({
                        ...submitCaseValues,
                        age: e.target.value,
                      })
                    }
                    autoComplete={false}
                    style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group controlId="sex" className="mb-3">
                  <Form.Label className="m-1 fw-bold">Gender</Form.Label>
                  <Form.Select
                    aria-label="Male"
                    style={{
                      borderRight: "none",
                      backgroundColor: "#f8f5fc",
                      height: "50px",
                    }}
                    onChange={(e) =>
                      setSubmitCaseValues({
                        ...submitCaseValues,
                        sex: e.target.value,
                      })
                    }
                  >
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group controlId="city" className="mb-3">
                  <Form.Label className="m-1 fw-bold">City</Form.Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="city"
                      value={submitCaseValues.city}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          city: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group controlId="state" className="mb-3">
                  <Form.Label className="m-1 fw-bold">State</Form.Label>
                  <InputGroup>
                    <FormControl
                      type={"text"}
                      placeholder="State"
                      value={submitCaseValues.state}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          state: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group controlId="country" className="mb-3">
                  <Form.Label className="m-1 fw-bold">Select Country</Form.Label>
                  <Form.Select
                    aria-label="Select Country Code"
                    style={{
                      borderRight: "none",
                      backgroundColor: "#f8f5fc",
                      height: "50px",
                    }}
                    onChange={(e) =>
                      setSubmitCaseValues({
                        ...submitCaseValues,
                        country: e.target.value,
                      })
                    }
                  >
                    <option>Select</option>
                    {allcodes.map((code, index) => (
                      <option
                        key={index + 1}
                        value={code.name}
                        style={{ width: "auto" }}
                      >
                        {code.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <h4>Other Information</h4>

            <Row>
              <Col sm={12} lg={12}>
                <Form.Group controlId="medicaldg" className="mb-4">
                  <Form.Label className="m-1 mb-2">
                    Medical Diagnosis(if known)
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      as={"textarea"}
                      type={"text"}
                      placeholder="type here..."
                      value={submitCaseValues.medicalDiagnosis}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          medicalDiagnosis: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <Form.Group controlId="symptomps" className="mb-4">
                  <Form.Label className="m-1 mb-2">
                    Symptoms as perceived by the subject; describe all health
                    problems and specific location,which side (left,right) etc.
                    Use lay terminology only; no medical terminology;
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      as={"textarea"}
                      type={"text"}
                      placeholder="type here..."
                      value={submitCaseValues.symptoms}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          symptoms: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <Form.Group controlId="operations" className="mb-4">
                  <Form.Label className="m-1 mb-2">
                    Past Operations/Accidents (with approximate dates if known,
                    month/year is fine)
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      as={"textarea"}
                      type={"text"}
                      placeholder="type here..."
                      value={submitCaseValues.pastOperations}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          pastOperations: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <Form.Group controlId="dominant" className="mb-4">
                  <Form.Label className="m-1 mb-2">
                    Dominant Personality Characteristics
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      as={"textarea"}
                      type={"text"}
                      placeholder="type here..."
                      value={submitCaseValues.personalityCharacteristics}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          personalityCharacteristics: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <Form.Group controlId="general" className="mb-4">
                  <Form.Label className="m-1 mb-2">
                    General Description: height (ft./in.), weight (lbs.), eye
                    color, hair color, complexion, glasses?, smoker?, occupation
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      as={"textarea"}
                      type={"text"}
                      placeholder="type here..."
                      value={submitCaseValues.desc}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          desc: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={12}>
                <Form.Group controlId="other" className="mb-4">
                  <Form.Label className="m-1 mb-2">
                    Any other Relevant Information not listed above
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      as={"textarea"}
                      type={"text"}
                      placeholder="type here..."
                      value={submitCaseValues.otherDesc}
                      onChange={(e) =>
                        setSubmitCaseValues({
                          ...submitCaseValues,
                          otherDesc: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <h4>Assignment and Authorization:</h4>
            <p>
              Do you accept the Assignment and Authorization shown below?
              Accepting this is equivalent to signing this document. (you must
              accept this agreement in order for us to accept your case)
            </p>
            <Form.Check
              type="checkbox"
              label={
                <div>
                  <p style={{ fontWeight: "bold", color: "black" }}>
                    Assignment and Authorization
                  </p>
                  <p>
                    I hereby authorize this website to furnish its subscribers,
                    students of Jose Silva's healing methods, and/or researchers
                    with the information contained in this Subject Information
                    Form, for their better understanding an d development of
                    caseworking techniques, used for the subjective detection
                    and correction of health abnormalities. I underst and that
                    completing and submitting this form does not guarantee a
                    place in the database or on the website, and that healing
                    benefit is not guaranteed. I further understand that there
                    is no charge for submitting a case.
                  </p>
                </div>
              }
              checked={termAccepted}
              onChange={() => setTermAccepted(!termAccepted)}
              className="mt-3"
            />

            <button className="benifits-card px-5 py-2 border-0 rounded mt-3">
            {loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Submit"
                )}
            </button>
          </Form>
        </div>
      </Container>
    </Modal>
  );
}

export default SubmitCase;
