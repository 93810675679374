import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import CustomCard from './Card';

const SingleShop = ({ data,index }) => {
    const {course_id, instructor_name, language, email,start_date, end_date,photo,course_name,delivery_method } = data;

    return (
        <>
            <div className="mx-3  my-5 classcard h-100">
                <div className="product mb-40 cardedited bg-white" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius: '15px',height:'650px',position:'relative'}}>
                    {/* <div style={{ borderRadius: '10px' }}>
                        <img src={data.class_image || '/assets/img/no_image.jpg'} alt={data.class_image} style={{width: '100%',height:data.class_image?"auto":"270px",padding:'10px',borderRadius:'20px' }} />
                    </div> */}
                    <CustomCard
                        index={index}
                        ins_image={photo}
                        title={course_name}
                        ins_name={instructor_name}
                        date={`${moment(start_date).format('Do MMM')}-${moment(end_date).format('Do MMM YY')}`}
                        language={language}
                        mode={delivery_method}
                    />
                    <div>
                        <p className='m-0 px-3 mt-3' style={{fontSize: instructor_name.length>30?'15px':'20px',fontWeight:'bold'}}><span style={{padding:'2px 5px',backgroundColor:'#e7ebec',borderRadius:'5px',marginRight:'10px',fontSize:'15px'}}>By </span>{instructor_name}</p>
                        <div className='ms-5'>
                            <div className='d-flex gap-2 px-2 align-items-center my-3'>
                                <img src="/assets/img/mail.webp" alt="" style={{width:'20px',height:'20px',objectFit:'contain'}}/>
                                <p className='m-0' style={{overflow:'hidden',overflowWrap:"break-word"}}>{email || 'No email available'}</p>
                            </div>
                            <div className='d-flex gap-2 px-2 align-items-center my-3'>
                                <img src="/assets/img/calender.webp" alt="" style={{width:'20px',height:'20px',objectFit:'contain'}}/>
                                <p className='m-0'>{`${moment(start_date).format('DD-MM-YYYY')} - ${moment(end_date).format('DD-MM-YYYY')}` || 'No date available'}</p>
                            </div>
                            <div className='d-flex gap-2 p-2 align-items-center'>
                                <p className='m-0 px-3 py-2 rounded'  style={{backgroundColor:'#e7ebec',textTransform:'capitalize'}}>Language - <span>{language}</span></p>
                                <img src="/assets/img/zoom.webp" alt="" style={{width:'80px',height:'50px',objectFit:'contain'}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', padding: '15px 10px', backgroundColor: '#233645', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px',position:'absolute',bottom:0 }}>
                        <Link to={`https://silvamethod.com/events/livedetails/${course_id}`} style={{ color: 'white',textDecoration:'none',fontWeight:'500'}}>Course Details</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleShop;