import React from "react";

function LeftContainer({title="",colored="",text=""}) {
  return (
    <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: "10" }}>
      <h1
        className="my-3 display-5 fw-bold ls-tight"
        style={{ color: "black" }}
      >
        {title}
        <span style={{ color: "#d901fc" }}> {colored}</span>
      </h1>
      <p className="mb-4 opacity-70" style={{ color: "#000",fontWeight:'700' }}>
       {text}
      </p>
    </div>
  );
}

export default LeftContainer;
