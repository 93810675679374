import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Public/Home/Home'
import AuthenticatedHome from './Private/AuthenticatedHome/AuthenticatedHome'
import PublicRoute from './Public/PublicRoute'
import PrivateRoute from './Private/PrivateRoute'
import Login from './Public/Login/Login'
import Page404 from './Public/Page404/Page404'
import ResetPassword from './Public/ResetPassword/ResetPassword'
import ZoomMeet from './Zoom/ZoomMeet'
import Terms from './Terms/Terms'

function AllRoutes() {
  return (
    
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/zoom' element={<ZoomMeet/>}/>
      <Route element={<PublicRoute/>}>
        <Route path='/authenticate' element={<Login/>}/>
        <Route path='/passwordreset/:token' element={<ResetPassword/>}/>
      </Route>
      <Route element={<PrivateRoute/>}>
        <Route path='/caseworking' element={<AuthenticatedHome/>}/>
      </Route>
      <Route path='/terms' element={<Terms/>}/>
      <Route path='*'element={<Page404/>}/>    
      </Routes>
  )
}

export default AllRoutes