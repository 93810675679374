import React, { useContext, useState } from "react";
import { Card, Col, Form, FormControl, Row, Spinner } from "react-bootstrap";
import { AllContext } from "../../../context/AllContext";
import { requestData2 } from "../../../utils/ApiRequests";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

function CaseHeroSection({ showAuthModal, setShowAuthModal }) {
  const cards = [
    {
      id: 1,
      title: "Submit a Case",
      image: "/assets/img/uploading.webp",
      onclick: () => setShowAuthModal({ state: true, name: "submitcase" }),
    },
    {
      id: 2,
      title: "Pick Up Case",
      image: "/assets/img/download.webp",
      onclick: () => setShowAuthModal({ state: true, name: "pickupcase" }),
    },
    {
      id: 2,
      title: "View My Case",
      image: "/assets/img/view.webp",
      onclick: () => setShowAuthModal({ state: true, name: "viewcase" }),
    },
  ];

  const [code, setCode] = useState("");
  const token = JSON.parse(localStorage.getItem("silvacasetoken"));
  const { updateUser, userData,isMobile } =
    useContext(AllContext);
  const [loading, setLoading] = useState({ state: false, name: "" });

  

  
  // const decoded = jwtDecode(token);
  // console.log(decoded,'ud')

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!code) {
      toast.error("please enter your code");
      return;
    }
    // console.log(code, "code");
    setLoading({ state: true, name: "verify" });

    const response = await requestData2("/auth/verifyEmail", "POST", {
      code: code.trim(),
    });
    if (response.success) {
        Swal.fire("Email verified successfully.Login again to see update", "", "success");
      if (token) {
        updateUser();
      }
    } else {
      toast.error(response.error);
    }
    setLoading({ state: false, name: "" });
  };

  const resendEmail = async (e) => {
    e.preventDefault();
    if (!userData || !userData?.email) {
      toast.error("unable to find email.");
      return;
    }
    setLoading({ state: true, name: "resend" });
    const response = await requestData2("/auth/resendemail", "POST", {
      email: userData?.email,
    });
    if (response?.success) {
      toast.success(response?.message);
    }
    // console.log(response,'res');
    setLoading({ state: false, name: "" });
  };

  return (
    <div
      className={`${
        isMobile ? "vh-100" : "vh-100"
      } w-100 d-flex justify-content-center align-items-center`}
    >
      {userData?.isEmailVerified ? (
        <div className="container">
          <Row className="justify-content-center">
            {cards.map((card, index) => (
              <Col lg={4} md={6} sm={12} key={index}>
                <div className="cardhover-border-visible">
                  <Card
                    className="py-5 shadow-lg"
                    style={{ borderRadius: "15px" }}
                    onClick={card.onclick}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="authcard-icon-section">
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#282282",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={card?.image}
                            alt=""
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <h5 className="text-center mt-3">{card?.title}</h5>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className="container d-flex justify-content-center align-items-center">
          <Card
            style={{
              width: isMobile ? "100%" : "50%",
              padding: isMobile ? "10px" : "20px",
            }}
          >
            <div>
              <h3
                class="my-3 display-5 fw-bold ls-tight"
                style={{ color: "black" }}
              >
                Verify
                <span style={{ color: "#d901fc" }}> {"Email"}</span>
              </h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label className="m-1 fw-bold">
                    Enter verification code{" "}
                  </Form.Label>
                  <FormControl
                    type="tel"
                    placeholder="Enter verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                  />
                </Form.Group>

                <button className="benifits-card px-5 py-3 border-0 rounded mt-2 me-3">
                  {loading.state && loading.name === "verify" ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  className="benifits-card px-5 py-3 border-0 rounded mt-2"
                  onClick={resendEmail}
                >
                  {loading.state && loading.name === "resend" ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Resend"
                  )}
                </button>
              </Form>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

export default CaseHeroSection;
